@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500&family=Crimson+Pro&family=Rubik+Scribble&family=Sometype+Mono&display=swap');

@media (min-width: 768px) {
    .md\:text-6xl {
        line-height: 1.25 !important
    }
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
}

.ring-container {
    position: relative;
    display: inline-block; /* Added to align with text */
    vertical-align: middle; /* Align with the middle of the text */
}

.circle {
    width: 8px;
    height: 8px;
    background-color: #B6D1A0;
    border-radius: 50%;
}

.ringring {
    border: 3px solid #B6D1A0;
    border-radius: 30px;
    height: 16px;
    width: 16px;
    position: absolute;
    left: -4px; /* Adjusted for centering */
    top: -4px; /* Adjusted for centering */
    animation: pulsate 1s ease-out infinite;
    opacity: 0;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        transform: scale(1.2, 1.2);
        opacity: 0.0;
    }
}


@tailwind base;
@tailwind components;
@tailwind utilities;